@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@300;500;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Mukta', sans-serif;
  color: white
}

.dash img:hover {
  cursor: pointer;
}

.flexDivMain {
  display: flex;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}


.hl2 {
  color: white;
  margin-top: 7%;
  margin-bottom: 7%;
}

.flexDiv {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

.hl {
  color: goldenrod;
}

.tgM button:hover {
  color: goldenrod;
}

.connectBtn:hover {
  /* Specify the final background color */
  background-color: #e99324;
  color: white;
}

.connectBtn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, goldenrod);
  transition: left 0.3s ease-in-out;
  /* Adjusted transition property */
}

.connectBtn:hover::before {
  left: 0;
}



.mint:hover {
  /* Specify the final background color */
  background-color: #cfc24a;
  color: white;
}

.mint::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, goldenrod);
  transition: left 0.3s ease-in-out;
  /* Adjusted transition property */
}

.mint:hover::before {
  left: 0;
}

@media screen and (max-width: 499px) {

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .tgM {
    display: none;
  }

  .flexDiv3 {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
  }

  .in3M {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    background-color: #00000000;
    z-index: 1000000;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4%;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: goldenrod;
    color: white;
    padding: 10px;
    font-size: 16px;
    width: 150px;
    border-radius: 100px;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: -10px;
    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn:hover::before {
    left: 0;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
  background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 100px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 52px;
    text-align: center;
    color: white;
    margin-bottom: 3%;
    letter-spacing: -0.015em;

  }

  .mintT {
    font-style: normal;
    font-size: 30px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.015em;
    font-weight: bold;
  }

  .mintCon1 {
    color: white;
    font-size: 16px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .dash {
    color: goldenrod;
    width: 18px;
    height: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 20px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintCon3- {
    color: white;
    font-family: 'Mukta', sans-serif;
    font-size: 22px;
    color: white;
    margin-left: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    background-color: goldenrod;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 20px;
  }

  .nftMintingAmount {
    font-size: 36px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 7px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 18px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    border: 1px solid goldenrod;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
    justify-content: center;
  }

  .nftMint {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .mint2 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    width: 80%;
  }

  .leftMintSection {
    width: 100%;
    justify-content: center;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    box-shadow: 10px 10px black;
    padding: 35px;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    line-height: 38px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 20px;
    text-shadow: 0 0 20px #9bf179;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: white;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 35px;
    height: 35px;
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 15px;
    margin-bottom: 10px;
    letter-spacing: 0.025em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .tgM {
    display: none;
  }

  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
  }

  .in3M {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    background-color: #00000000;
    z-index: 1000000;
 
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: goldenrod;
    color: white;
    padding: 15px;
    font-size: 18px;
    width: 240px;
    border-radius: 100px;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    transition: 0.3s ease-in-out;
    margin-left: 10px;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn:hover::before {
    left: 0;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
  background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 100px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 52px;
    text-align: center;
    color: white;
    margin-bottom: 3%;
    letter-spacing: -0.015em;

  }

  .mintT {
    font-style: normal;
    font-size: 45px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.015em;
    font-weight: bold;
  }

  .mintCon1 {
    color: white;
    font-size: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .dash {
    color: goldenrod;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintCon3- {
    color: white;
    font-family: 'Mukta', sans-serif;
    font-size: 22px;
    color: white;
    margin-left: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    background-color: goldenrod;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 20px;
  }

  .nftMintingAmount {
    font-size: 36px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 7px;
    margin-top: 40px;
    padding: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 18px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    border: 1px solid goldenrod;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
    justify-content: center;
  }

  .nftMint {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .mint2 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    width: 80%;
  }

  .leftMintSection {
    width: 100%;
    justify-content: center;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    box-shadow: 10px 10px black;
    padding: 35px;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    line-height: 38px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 20px;
    text-shadow: 0 0 20px #9bf179;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: white;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
     border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 15px;
    letter-spacing: 0.025em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
  }

  .tgM {
    display: none;
  }

  .in3M {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    background-color: #00000000;
    z-index: 1000000;
    margin-bottom: 5%;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: goldenrod;
    color: white;
    padding: 15px;
    font-size: 18px;
    width: 240px;
    border-radius: 100px;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
 
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn:hover::before {
    left: 0;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
  background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 100px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 52px;
    text-align: center;
    color: white;
    margin-bottom: 3%;
    letter-spacing: -0.015em;

  }

  .mintT {
    font-style: normal;
    font-size: 45px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.015em;
    font-weight: bold;
  }

  .mintCon1 {
    color: white;
    font-size: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .dash {
    color: goldenrod;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintCon3- {
    color: white;
    font-family: 'Mukta', sans-serif;
    font-size: 22px;
    color: white;
    margin-left: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    background-color: goldenrod;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 20px;
  }

  .nftMintingAmount {
    font-size: 36px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 7px;
    margin-top: 40px;
    padding: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 22px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    border: 1px solid goldenrod;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftMint {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .mint2 {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
    width: 80%;
  }

  .leftMintSection {
    width: 90%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 10%;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    box-shadow: 10px 10px black;
    padding: 35px;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    line-height: 38px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 20px;
    text-shadow: 0 0 20px #9bf179;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: white;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 15px;
    letter-spacing: 0.025em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
  }

  .tgM {
    display: none;
  }

  .in3M {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: goldenrod;
    color: white;
    padding: 15px;
    font-size: 18px;
    width: 240px;
    border-radius: 100px;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
 
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn:hover::before {
    left: 0;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
  background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 100px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 52px;
    text-align: center;
    color: white;
    margin-bottom: 3%;
    letter-spacing: -0.015em;

  }

  .mintT {
    font-style: normal;
    font-size: 45px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.015em;
    font-weight: bold;
  }

  .mintCon1 {
    color: white;
    font-size: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .dash {
    color: goldenrod;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintCon3- {
    color: white;
    font-family: 'Mukta', sans-serif;
    font-size: 22px;
    color: white;
    margin-left: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    background-color: goldenrod;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 20px;
  }

  .nftMintingAmount {
    font-size: 36px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 7px;
    margin-top: 40px;
    padding: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 22px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    border: 1px solid goldenrod;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .nftMint {
    width: 360px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .mint2 {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .leftMintSection {
    width: 45%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    box-shadow: 10px 10px black;
    padding: 35px;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    line-height: 38px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 20px;
    text-shadow: 0 0 20px #9bf179;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: white;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 15px;
    letter-spacing: 0.025em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
  }


  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    padding-left: 20px;
    padding: 12px;
    border: 2px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .tgM {
    display: none;
  }

  .in3M {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: goldenrod;
    color: white;
    padding: 15px;
    font-size: 18px;
    width: 240px;
    border-radius: 100px;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
 
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn:hover::before {
    left: 0;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
  background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 100px;
    display: none;
  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
  box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    padding: 15px;
    border: none;
    width: 160px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 52px;
    text-align: center;
    color: white;
    margin-bottom: 3%;
    letter-spacing: -0.015em;

  }

  .mintT {
    font-style: normal;
    font-size: 52px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.015em;
    font-weight: bold;
  }

  .mintCon1 {
    color: white;
    font-size: 18px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .mintCon1-N {
    font-size: 18px;
  }

  .dash {
    color: goldenrod;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 25px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .mintCon3- {
    color: white;
    font-family: 'Mukta', sans-serif;
    font-size: 22px;
    color: white;
    margin-left: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    background-color: goldenrod;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-bottom-right-radius: 20px;
  }

  .nftMintingAmount {
    font-size: 36px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 7px;
    margin-top: 40px;
    padding: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 22px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    border: 1px solid goldenrod;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .nftMint {
    width: 450px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .mint2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .leftMintSection {
    width: 40%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    box-shadow: 10px 10px black;
    padding: 35px;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 24px;
    text-align: left;
    line-height: 38px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 20px;
    text-shadow: 0 0 20px #9bf179;

  }

  .errorMessage {
    margin-top: 3%;
    font-size: 16px;
    color: #fc4741;
    text-align: center;

  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: white;

  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    }

  .icons img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
     border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 15px;
    letter-spacing: 0.025em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1920px) {

  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 30px;
    padding-left: 30px;
    padding: 16px;
    border: 3px groove goldenrod;
  }

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 30px;
    padding-left: 30px;
    padding: 16px;
    border: 3px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .in3M {
    display: none;
  }

  .tgM {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 60px;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 22px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: white;
    padding: 20px;
    width: 350px;
    background-color: goldenrod;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
     border-radius: 50px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    font-size: 25px;
    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
    background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 150px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;

  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: white;
    padding: 23px;
    border: none;
    width: 230px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: white;
    padding: 23px;
    border: none;
    width: 230px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 75px;
    text-align: center;
    text-align: center;
    color: white;
    margin-bottom: 3%;
  }

  .mintT {
    font-style: normal;
    font-size: 75px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.01em;
    font-weight: bold;

  }

  .mintCon1 {
    color: white;
    font-size: 24px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 120px;
  }

  .mintCon1-N {
    font-size: 24px;
  }


  .dash {
    color: goldenrod;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 24px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 40px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 24px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 120px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 24px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;

  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .nftMintingAmount {
    font-size: 48px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 15px;
    margin-top: 40px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 28px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    margin-bottom: auto;
    border: 2px solid goldenrod;
    border-radius: 30px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .nftMint {
    width: 600px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }

  .mint2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .leftMintSection {
    width: 40%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 75px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    padding: 35px;
    box-shadow: 10px 10px black;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .whiteLine {
    width: 7px;
    height: 12vh;
    background-color: white;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 35px;
    text-align: left;
    line-height: 54px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 30px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
 
  }

  .icons img {
    width: 55px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
      border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 21px;
    letter-spacing: 0.035em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 50px;
    padding-left: 50px;
    padding: 25px;
    border: 6px groove goldenrod;
  }

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 50px;
    padding-left: 50px;
    padding: 25px;
    border: 6px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .in3M {
    display: none;
  }

  .tgM {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 70px;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 30px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 38px;
    margin-right: 38px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: white;
    padding: 25px;
    width: 450px;
    background-color: goldenrod;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
     transition: -webkit-transform .2s;
     transition: transform .2s;
     transition: transform .2s, -webkit-transform .2s;

    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }


  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
    background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 200px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }


  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;

  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: white;
    padding: 23px;
    border: none;
    padding: 32px;
    width: 290px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: white;
    padding: 23px;
    border: none;
    padding: 32px;
    width: 290px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 100px;
    text-align: center;
    text-align: center;
    color: white;
    margin-bottom: 3%;
  }

  .mintT {
    font-style: normal;
    font-size: 100px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.01em;
    font-weight: bold;

  }

  .mintCon1 {
    color: white;
    font-size: 28px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 130px;
  }

  .mintCon1-N {
    font-size: 28px;
  }

  .dash {
    color: goldenrod;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 28px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 28px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 150px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 28px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;

  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 28px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .nftMintingAmount {
    font-size: 65px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 23px;
    margin-top: 60px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 36px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    margin-bottom: auto;
    border: 4px solid goldenrod;
    border-radius: 50px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .nftMint {
    width: 800px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
  }

  .mint2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .leftMintSection {
    width: 40%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 100px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    padding: 58px;
    box-shadow: 18px 18px black;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 48px;
    text-align: left;
    line-height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 35px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 27px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
 
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 18px;
    margin-right: 18px;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 29px;
    letter-spacing: 0.035em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 3840px) {


  .flexDiv3 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 70px;
    padding-left: 70px;
    padding: 25px;
    border: 6px groove goldenrod;
  }

  .flexDiv3-2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-right: 70px;
    padding-left: 70px;
    padding: 25px;
    border: 6px groove goldenrod;
    border-left-color: rgba(255, 0, 0, 0);
  }

  .in3M {
    display: none;
  }

  .tgM {
    display: none;
  }


  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background-color: #00000000;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 45px;
    cursor: pointer;
    z-index: 10000;
    color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    letter-spacing: 0.2em;
    font-style: normal;
    font-weight: 400;
  }

  #fontSize:hover {
    color: #3e96ee;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;

    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {

    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 55px;
    margin-right: 55px;
    text-transform: uppercase;
    font-family: 400;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: white;
    padding: 40px;
    width: 650px;
    background-color: goldenrod;
    border: none;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    font-style: normal;
    font-weight: 500;
    font-size: 53px;
     border-radius: 100px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: white;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    /* background: rgb(6, 62, 104);
    background: linear-gradient(273deg, rgba(6, 62, 104, 1) 0%, rgba(9, 3, 40, 1) 20%, rgba(8, 15, 53, 1) 80%, rgba(6, 62, 104, 1) 100%);*/
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/0_2.cbec6b54.jpg) no-repeat center center fixed;
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .logo {
    width: 300px;
    display: none;

  }


  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Mint {
    padding-top: 3%;
    padding-bottom: 2%;

  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.jpg') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background-color: #09032800;
  }



  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .connectBtn2 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: white;
    padding: 23px;
    border: none;
    padding: 45px;
    width: 450px;
    margin-top: 3%;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */


    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }

  .connectBtn2:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn2::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn2:hover::before {
    left: 0;
  }

  .connectBtn3 {
    /*background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    box-shadow: 0px 24px 55px rgba(5, 170, 221, 0.25);*/
    background-color: goldenrod;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: white;
    border: none;
    padding: 45px;
    width: 450px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
  }


  .connectBtn3:hover {
    /* Specify the final background color */
    background-color: #e99324;
    color: white;
  }

  .connectBtn3::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, goldenrod);
    transition: left 0.3s ease-in-out;
    /* Adjusted transition property */
  }

  .connectBtn3:hover::before {
    left: 0;
  }

  .conT2 {
    font-style: normal;
    font-size: 150px;
    text-align: center;
    text-align: center;
    color: white;
    margin-bottom: 3%;
  }

  .mintT {
    font-style: normal;
    font-size: 150px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
    letter-spacing: -0.01em;
    font-weight: bold;

  }

  .mintCon1 {
    color: white;
    font-size: 42px;
    letter-spacing: 0.025em;
    text-align: left;
    width: 230px;
  }

  .mintCon1-N {
    font-size: 42px;
  }

  .dash {
    color: goldenrod;
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .dashDiv {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .flexDiv2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }


  .mintCon1-1 {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon1-2 {
    color: white;
    font-size: 30px;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 30px;
  }


  .line {
    width: 100%;
    height: 1px;
    background-color: goldenrod;
    margin-top: 30px;
  }

  .mintCon2 {
    font-family: 'Mukta', sans-serif;
    font-size: 42px;
    color: white;
    font-weight: lighter;
    text-align: right;
    width: 230px;
  }

  .mintCon2--2 {
    font-family: 'Mukta', sans-serif;
    font-size: 42px;
    color: white;
    font-weight: lighter;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .mintCon2--1 {
    font-family: 'Mukta', sans-serif;
    font-size: 42px;
    color: rgba(255, 255, 255, 0.658);
    font-weight: lighter;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .mintCon2-1 {
    font-size: 18px;
    font-weight: 500;
  }

  .mintCon3 {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintCon3 img {
    width: 50px;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .nftMintingAmount {
    font-size: 95px;
  }

  .mintBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(218, 165, 32, 0.425);
    -webkit-backdrop-filter: blur(5);
            backdrop-filter: blur(5);
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
  }

  .mintBar2 {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }

  .max {
    border: none;
    background: goldenrod;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .mint {
    background-color: goldenrod;
    color: white;
    width: 100%;
    border: none;
    padding: 27px;
    margin-top: 60px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 52px;
    transition: 0.3s ease-in-out;
    /* Adjusted transition property */

    /* Animation */
    position: relative;
    overflow: hidden;
  }



  .controler {
    display: flex;
    justify-content: center;
  }

  .rightMintSection {
    margin-top: auto;
    margin-bottom: auto;
    border: 6px solid goldenrod;
    border-radius: 70px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .nftMint {
    width: 1200px;
    margin-bottom: 20px;
    border-top-right-radius: 70px;
    border-top-left-radius: 70px;
  }

  .mint2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .leftMintSection {
    width: 40%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .comT {
    color: white;
    font-size: 56px;
    line-height: 60px;

  }

  .joinBtn {
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
    box-shadow: 0px 42px 32px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 18px;
    font-family: 'Mukta', sans-serif;
    color: #0581E5;
    border: none;
    padding: 15px;
    width: 160px;
    margin-top: 2%;
  }

  .comImg {
    width: 21%;
    margin-left: auto;
    position: absolute;
    margin-left: 60%;
    margin-top: -35%;
  }

  .comMain {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #090328;
  }

  .comSection {
    background: linear-gradient(131.79deg, #0581E5 0%, #e99324 100%);
    border-radius: 16px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 55px;

  }

  .cT {
    color: white;
    font-weight: 400;
    font-size: 150px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  .cT2 {
    font-size: 44px;
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyCon {
    color: white;
    background-color: #78E5FC;
    padding: 58px;
    box-shadow: 23px 23px black;
  }

  .connectMainDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .mainDiv div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .whiteLine {
    width: 12px;
    height: 12vh;
    background-color: white;
  }

  .details {
    display: flex;
  }

  .details1 {
    margin-right: 90px;
  }

  .blue {
    color: goldenrod;
    font-size: 20px;
  }

  .white {
    color: white;
    font-size: 56px;
    color: #FFFFFF;
    text-shadow: 0px 4px 16px rgba(213, 121, 255, 0.521);
  }

  .storyCon p {
    color: white;
    font-family: 'Covered By Your Grace', cursive;
    font-style: normal;
    font-size: 70px;
    text-align: left;
    line-height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .rmBoxMain {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .rmBoxMain1 {
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rmBox1 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    font-size: 18px;
    margin-right: 40px;
  }

  .numRm {
    background-color: #482662;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
  }

  .numFlex {
    display: flex;

  }

  .numberAndCon {
    display: flex;
  }

  .boxRmMain1 {
    margin-top: 50px;
  }

  .rmBox2 {
    background: linear-gradient(127.41deg, rgba(218, 37, 226, 0.2) 14.83%, rgba(87, 24, 243, 0.2) 110.24%, rgba(255, 245, 238, 0.2) 110.24%);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    border-radius: 14px;
    padding: 40px;
    margin-top: auto;
    font-size: 18px;
  }

  .dm {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: white;
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .successfully {
    text-align: center;
    margin-top: 3%;
    color: #1ee03e;
    font-size: 45px;
    text-shadow: 0 0 20px #9bf179;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 35px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 35px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: white;
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: white;
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 110px;
    height: 110px;
    margin-left: 26px;
    margin-right: 26px;
    box-shadow: 0 0 10px #01a5c2;
     border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.4);
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: white;
    font-size: 45px;
    letter-spacing: 0.035em;
  }

  .terms {
    display: flex;
    font-family: 'Mukta', sans-serif;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Mukta', sans-serif;
    font-size: 16px;

  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}
